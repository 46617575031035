<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body>
            <b-card-body>
                <b-row>

                    <b-col md="12">
                        <span @click="$router.go(-1)">
                            <img :src="require('@/assets/images/back.png')" style="width:30px;  margin-right:20px" align="left"/>
                        </span>
                        <h4 v-if="device" class="card-title float-left"> 
                            {{ device.device_name | capitalize }}
                        </h4>                        
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>

        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                @input="filterTable"
                            />
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
            >

                <template #cell(sensor_name)="items">
                    <p class="wordBreak">{{ items.item.sensor_name | capitalize}}</p>
                </template>

                <template #cell(percentage)="items">
                    <p class="wordBreak" v-if="items.item.percentage >= 0">{{ items.item.percentage + '%'}}</p>
                    <p v-else>N/A</p>
                </template>

                <template #cell(status)="items">
                    <div v-if="items.item.percentage && items.item.percentage != 'N/A'">
                        <p v-if="items.item.percentage >= 0 && items.item.percentage <= 25">Empty</p>
                        <p v-if="items.item.percentage > 25 && items.item.percentage <= 75">Mid</p>
                        <p v-if="items.item.percentage > 75">Full</p>
                    </div>
                    <div v-else>N/A</div>
                </template>

                <template #cell(actions)="items">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <!-- 3 dot menu -->
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <!-- View stats -->
                        <b-dropdown-item :to="{ name: 'sensor-stats', params: { device_id: items.item.device_id._id, sensor_id: items.item.sensor_id, sensor_name: items.item.sensor_name, sensor_type: items.item.sensor_type} }">
                            <feather-icon icon="BarChart2Icon" />
                            <span class="align-middle ml-50">View Stats</span>
                        </b-dropdown-item>

                    </b-dropdown> 
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
    
    </div>
</template>

<script>
    import {
        BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm
    } from 'bootstrap-vue'
    import { POST_API } from "../../../store/actions.type"
    import vSelect from 'vue-select';

    export default {
        components: {
            vSelect, BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm
        },

        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                device : null,

                tableColumns: [
                    { key: 'sensor_id', label: 'Sensor Id', sortable: true , thStyle:  {width: '15%'}},
                    { key: 'sensor_name', label: 'Sensor Name', sortable: true , thStyle:  {width: '35%'}},
                    { key: 'sensor_type', label: 'Sensor Type', sortable: true , thStyle:  {width: '25%'}},
                    // { key: 'current_value', label: 'Current Value', sortable: false , thStyle:  {width: '20%'}},
                    { key: 'percentage', label: 'Percentage', sortable: false , thStyle:  {width: '20%'}},
                    { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '5%'}},
                ], 

                // b-table and pagination options
                    items           : [],
                    isSortDirDesc   : true,
                    perPageOptions  : [10, 20, 50, 100],
                    perPage         : 10,
                    sortBy          : 'id',
                    totalRecords    : 0,
                    currentPage     : 1,
                    searchQuery     : '',
                    from            : null,
                    to              : null,

            }
        },

        methods : {
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        device_id     : this.$route.params.id,
                    },
                    api: '/api/sensor-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            getDeviceDetails(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id : this.$route.params.id
                    },
                    api:"/api/device-detail",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.device = this.$store.getters.getResults.data;
                    }
                });
            },

        },
        mounted(){
            this.getDeviceDetails();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
